<template>
    <div v-if="Object.keys(orderLine).length">
        <h3 class="c-card__title font-weight-normal"><span v-if="!title">Product</span>{{ title }}</h3>
        <h4 class="c-card__subtitle">{{ orderLine.product.productName }}</h4>
        <p class="text-break">{{ orderLine.product.sku }}</p>
        <table>
            <tr v-for="(attribute, key) in orderLine.product.attributes" :key="key">
                <template v-if="attribute.attributeName">
                    <td>
                        <div v-for="(attributeName, attributeNameKey) in attribute.attributeName.toString().split('#')" :key="attributeNameKey">
                            {{ attributeName }}
                        </div>
                    </td>
                    <td class="text-break">
                        <div v-for="(attributeOption, attributeOptionKey) in attribute.attributeOption.toString().split('#')" :key="attributeOptionKey">
                            <strong>{{ attributeOption }}</strong>
                        </div>
                    </td>
                </template>
                <template v-else>
                    <td>{{ key }}:</td>
                    <td class="text-break"><strong>{{ attribute }}</strong></td>
                </template>
            </tr>
            <template v-if="orderLine.product.additionalProductInfo.customVariables">
                <tr v-for="(customVariable, customVarKey) in orderLine.product.additionalProductInfo.customVariables" :key="customVarKey">
                    <td>{{ customVarKey }}:</td>
                    <td><strong>{{ customVariable }} cm</strong></td>
                </tr>
            </template>
            <tr>
                <td>Quantity:</td>
                <td><strong>{{ orderLine.product.quantity }}</strong></td>
            </tr>
        </table>
        <table v-if="orderLine.shopAttributes">
            <tr v-for="(attribute, key) in orderLine.shopAttributes" :key="key">
                <template v-if="attribute.attributeName">
                    <td>{{ attribute.attributeName }}:</td>
                    <td><strong>{{ attribute.attributeOption }}</strong></td>
                </template>
                <template v-else>
                    <td>{{ key }}:</td>
                    <td><strong>{{ attribute }}</strong></td>`
                </template>
            </tr>
        </table>
        <table v-if="orderLine.product.additionalProductInfo.width">
            <tr>
                <td>width:</td>
                <td><strong>{{ orderLine.product.additionalProductInfo.width }}</strong></td>
            </tr>
            <tr>
                <td>length:</td>
                <td><strong>{{ orderLine.product.additionalProductInfo.length }}</strong></td>
            </tr>
            <tr>
                <td>unit:</td>
                <td><strong>{{ orderLine.product.additionalProductInfo.unit }}</strong></td>
            </tr>
        </table>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name: 'ProductSummary',
    props: {
        title: {
            type: String,
        },
    },
    computed: {
        ...mapState('order', ['orderLine']),
    },
}
</script>
