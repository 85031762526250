<template>
    <div class="c-shipment-events">
        <h4>Shipment events</h4>
        <div v-for="item in items" :key="item.eventId">
            <p>- {{ item.event }} <span>({{ item.dateEvent }})</span></p>
        </div>
    </div>
</template>

<script>
import CONFIG from '@root/config'
import ResourcesView from '@/views/ResourcesView'
import { mapState } from 'vuex'

export default {
    name: 'ShipmentEventsList',
    mixins: [ResourcesView],
    data () {
        return {
            isNotApplicationBound: true,
        }
    },
    computed: {
        ...mapState('order', ['order', 'orderLine']),
    },
    created () {
        this.url = CONFIG.API.ROUTES.ORDERS.ORDERLINE.SHIPMENT_EVENTS.replace('{orderId}', this.order.orderId)
            .replace('{accountId}', this.$route.params.accountId).replace('{orderLineId}', this.orderLine.orderLineId)
    },
}
</script>

<style lang="scss" scoped>
    .c-shipment-events {
        border-top: 1px solid #E2E2E2;
        padding: 5px 0px;
        height: 80px;
        overflow-y: auto;

        h4 {
            margin-bottom: 5px;
            margin-top: 5px;
            font-size: 14px;
        }

        p {
            margin-top: 0;
            font-size: 12px;
            span {
                opacity: .8;
                font-size: 11px;
            }
        }
    }
</style>
