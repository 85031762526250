<template>
    <div class="row" v-if="Object.keys(order).length">
        <div class="col-sm-4 mb-4">
            <mercur-card class="h-100">
                <product-summary></product-summary>
                <hr>
                <product-pricing></product-pricing>
            </mercur-card>
        </div>
        <div class="col-sm-4 mb-4">
            <mercur-card class="mb-3">
                <customer-info v-if="Object.keys(order).length" :billingAddress="order.billingInfo.address" :action="billingUrl"></customer-info>
            </mercur-card>
            <shipping-info></shipping-info>
        </div>
        <div class="col-sm-4 mb-4">
            <mercur-card class="mb-3">
                <h3 class="font-weight-normal">Notes</h3>
                <log-form></log-form>
            </mercur-card>
            <mercur-card>
                <h3 class="font-weight-normal">Log</h3>
                <log-list :keywords="['order line', 'artwork', 'complaint']"></log-list>
            </mercur-card>
        </div>
    </div>
</template>

<script>
import ProductSummary from '@/components/elements/order/ProductSummary'
import ProductPricing from '@/components/elements/order/ProductPricing'
import CustomerInfo from '@/components/elements/order/CustomerInfo'
import ShippingInfo from '@/components/elements/order/ShippingInfo'
import LogList from '@/components/elements/order/LogList'
import LogForm from '@/components/elements/order/LogForm'
import CONFIG from '@root/config'
import { mapState } from 'vuex'

export default {
    name: 'OrderLineInformation',
    components: { ProductSummary, ProductPricing, CustomerInfo, ShippingInfo, LogForm, LogList },
    computed: {
        ...mapState('order', ['order']),
        billingUrl () {
            if (!Object.keys(this.order).length) {
                return
            }

            return CONFIG.API.ROUTES.ORDERS.BILLING
                .replace('{accountId}', this.$route.params.accountId)
                .replace('{orderId}', this.order.orderId)
        },
    },
}
</script>
