<template>
    <div v-if="orderLine && Object.keys(orderLine).length">
        <table>
            <tr>
                <td>Supplier: </td>
                <td v-if="supplierData[orderLine.orderLineId]"><strong>{{ supplierData[orderLine.orderLineId].supplierName }}</strong></td>
            </tr>
            <tr>
                <td>Ships from: </td>
                <td v-if="turnaroundCalculation"><strong>{{ turnaroundCalculation.countryFrom }}</strong> - Ships to: <strong>{{ turnaroundCalculation.countryTo }}</strong></td>
            </tr>
        </table>
        <table class="c-data-table c-data-table--full">
            <thead>
                <tr>
                    <th></th>
                    <th class="text-right">Local currency</th>
                    <th class="text-right">Main currency</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Product Sales Price:</td>
                    <td class="text-right" v-if="prices">
                        <strong v-if="prices.local">
                            <currency :value="prices.local.salesPrice" :type="order.currency" />
                        </strong>
                    </td>
                    <td class="text-right" v-if="prices">
                        <strong v-if="prices.original">
                            <currency :value="prices.original.salesPrice" />
                        </strong>
                    </td>
                </tr>
                <tr class="c-data-table__bottom-border">
                    <td>Shipping Sales Price:</td>
                    <td class="text-right" v-if="prices">
                        <strong v-if="prices.local">
                            <currency :value="prices.local.shippingPrice" :type="order.currency" />
                        </strong>
                    </td>
                    <td class="text-right" v-if="prices">
                        <strong v-if="prices.original">
                            <currency :value="prices.original.shippingPrice" />
                        </strong>
                    </td>
                </tr>
                <template v-if="Object.keys(orderLine.orderLineTotals).length">
                    <tr>
                        <td>Discount:</td>
                        <td class="text-right" v-if="prices">
                            <strong v-if="prices.local">
                                <currency :value="prices.local.discount" :type="order.currency" />
                            </strong>
                        </td>
                        <td class="text-right" v-if="prices">
                            <strong v-if="prices.original">
                                <currency :value="prices.original.discount" />
                            </strong>
                        </td>
                    </tr>
                    <tr>
                        <td>Refund:</td>
                        <td class="text-right" v-if="prices">
                            <strong v-if="prices.local">
                                <currency :value="prices.local.refund" :type="order.currency" />
                            </strong>
                        </td>
                        <td class="text-right" v-if="prices">
                            <strong v-if="prices.original">
                                <currency :value="prices.original.refund" />
                            </strong>
                        </td>
                    </tr>
                    <tr>
                        <td>Subtotal:</td>
                        <td class="text-right" v-if="prices">
                            <strong v-if="prices.local">
                                <currency :value="prices.local.subtotal" :type="order.currency" />
                            </strong>
                        </td>
                        <td class="text-right" v-if="prices">
                            <strong v-if="prices.original">
                                <currency :value="prices.original.subtotal" />
                            </strong>
                        </td>
                    </tr>
                    <tr class="c-data-table__bottom-border">
                        <td>Total:</td>
                        <td class="text-right" v-if="prices">
                            <strong v-if="prices.local">
                                <currency :value="prices.local.total" :type="order.currency" />
                            </strong>
                        </td>
                        <td class="text-right" v-if="prices">
                            <strong v-if="prices.original">
                                <currency :value="prices.original.total" />
                            </strong>
                        </td>
                    </tr>
                </template>
                <tr>
                    <td>Product cost price</td>
                    <td class="text-right" v-if="costs">
                        <strong v-if="costs.local">
                            <currency :value="costs.local.product" :type="order.currency" />
                        </strong>
                    </td>
                    <td class="text-right" v-if="costs">
                        <strong v-if="costs.original">
                            <currency :value="costs.original.product" />
                        </strong>
                    </td>
                </tr>
                <tr class="c-data-table__bottom-border">
                    <td>Shipping cost price</td>
                    <td class="text-right" v-if="costs">
                        <strong v-if="costs.local">
                            <currency :value="costs.local.shipping" :type="order.currency" />
                        </strong>
                    </td>
                    <td class="text-right" v-if="costs">
                        <strong v-if="costs.original">
                            <currency :value="costs.original.shipping" />
                        </strong>
                    </td>
                </tr>
                <tr>
                    <td>Gross profit</td>
                    <td class="text-right" v-if="profit"><strong><currency :value="profit.local.gross" :type="order.currency" /></strong></td>
                    <td class="text-right" v-if="profit"><strong><currency :value="profit.original.gross" /></strong></td>
                </tr>
                <tr>
                    <td>Gross profit margin</td>
                    <td class="text-right" v-if="profit"><strong>{{ profit.local.margin }}%</strong></td>
                    <td class="text-right" v-if="profit"><strong>{{ profit.original.margin }}%</strong></td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name: 'ProductPricing',
    computed: {
        ...mapState('order', ['order', 'orderLine', 'turnaroundCalculation', 'supplierData']),
        ...mapState('shop', ['currencyConversionRates']),
        prices () {
            if (Object.keys(this.orderLine.orderLineTotals).length) {
                const t = this.orderLine.orderLineTotals
                const conversionValue = this.currencyConversionRates[this.order.currency]['EUR']
                return {
                    local: {
                        salesPrice: parseFloat(t.productsPrice),
                        shippingPrice: parseFloat(t.shippingPrice),
                        discount: parseFloat(t.reduction),
                        refund: parseFloat(t.refund),
                        subtotal: parseFloat(t.subtotal),
                        total: parseFloat(t.total),
                    },
                    original: {
                        salesPrice: parseFloat((t.productsPrice * conversionValue).toFixed(2)),
                        shippingPrice: parseFloat((t.shippingPrice * conversionValue).toFixed(2)),
                        discount: parseFloat((t.reduction * conversionValue).toFixed(2)),
                        refund: parseFloat((t.refund * conversionValue).toFixed(2)),
                        subtotal: parseFloat((t.subtotal * conversionValue).toFixed(2)),
                        total: parseFloat((t.total * conversionValue).toFixed(2)),
                    },
                }
            }

            if (!this.turnaroundCalculation || !this.turnaroundCalculation.priceCalculation) {
                return null
            }

            const key = Object.keys(this.turnaroundCalculation.priceCalculation.currencyConversions).filter(key => key !== 'original')[0]
            return {
                local: {
                    salesPrice: parseFloat(this.turnaroundCalculation.priceCalculation.currencyConversions[key].salesPrice),
                    shippingPrice: parseFloat(this.turnaroundCalculation.priceCalculation.currencyConversions[key].shippingPrice),
                    total: parseFloat(this.turnaroundCalculation.priceCalculation.currencyConversions[key].salesPrice),
                },
                original: {
                    salesPrice: parseFloat(this.turnaroundCalculation.priceCalculation.currencyConversions.original.salesPrice),
                    shippingPrice: parseFloat(this.turnaroundCalculation.priceCalculation.currencyConversions.original.shippingPrice),
                    total: parseFloat(this.turnaroundCalculation.priceCalculation.currencyConversions.original.salesPrice),
                },
            }
        },
        costs () {
            if (!this.turnaroundCalculation || !this.turnaroundCalculation.priceCalculation) {
                return null
            }

            const key = Object.keys(this.turnaroundCalculation.priceCalculation.currencyConversions).filter(key => key !== 'original')[0]
            return {
                local: {
                    product: parseFloat((this.turnaroundCalculation.supplierCost * this.currencyConversionRates['EUR'][this.order.currency]).toFixed(2)),
                    shipping: parseFloat(this.turnaroundCalculation.priceCalculation.currencyConversions[key].serviceLevelCost),
                },
                original: {
                    product: parseFloat(this.turnaroundCalculation.supplierCost),
                    shipping: parseFloat(this.turnaroundCalculation.priceCalculation.currencyConversions.original.serviceLevelCost),
                },
            }
        },
        profit () {
            if (!this.prices || !this.costs) {
                return
            }
            const localGross = this.prices.local.subtotal - this.costs.local.product - this.costs.local.shipping
            const originalGross = this.prices.original.subtotal - this.costs.original.product - (this.costs.original.shipping ? this.costs.original.shipping : 0)
            return {
                local: {
                    gross: localGross.toFixed(2),
                    margin: this.prices.local.salesPrice === 0 ? 'can not calculate' : (localGross / this.prices.local.salesPrice * 100).toFixed(2),
                },
                original: {
                    gross: originalGross.toFixed(2),
                    margin: this.prices.original.salesPrice === 0 ? 'can not calculate' : (originalGross / this.prices.original.salesPrice * 100).toFixed(2),
                },
            }
        },
    },
}
</script>
