<template>
    <mercur-card class="c-shipping-info">
        <div v-if="Object.keys(orderLine).length">
            <h3 class="c-card__title font-weight-normal">Shipping</h3>
            <table class="c-data-table">
                <tr>
                    <td>Carrier:</td>
                    <td>{{ orderLine.product.carrier.carrierName }}</td>
                </tr>
                <tr>
                    <td>Dispatch date:</td>
                    <td v-if="orderLine.deliveryInfo.dispatchDate">{{ orderLine.deliveryInfo.dispatchDate }}</td>
                </tr>
                <tr>
                    <td>Target Dispatch date:</td>
                    <td v-if="orderLine.deliveryInfo.targetDispatchDate">{{ orderLine.deliveryInfo.targetDispatchDate }}</td>
                </tr>
                <tr>
                    <td>Target delivery date:</td>
                    <td v-if="orderLine.deliveryInfo.targetDeliveryDate">{{ orderLine.deliveryInfo.targetDeliveryDate }}
                    </td>
                </tr>
                <tr>
                    <td>Delivery date:</td>
                    <td v-if="orderLine.deliveryInfo.deliveryDate">{{ orderLine.deliveryInfo.deliveryDate }}
                    </td>
                </tr>
                <tr>
                    <td>Product weight:</td>
                    <td v-if="orderLine.product.productInfo.weight">{{ orderLine.product.productInfo.weight.toFixed(3) }}</td>
                </tr>
                <tbody v-if="orderLine.shipment">
                <tr>
                    <td class="c-data-table__no-wrap">Tracking nummer:</td>
                    <td>{{ orderLine.shipment.carrierReference }}</td>
                </tr>
                <tr>
                    <td>Tracking url:</td>
                    <td><a :href="orderLine.shipment.trackingUrl" target="_blank">{{ orderLine.shipment.trackingUrl }}</a></td>
                </tr>
                </tbody>
            </table>
            <hr>
            <p><b>Delivery address</b> <span data-e2e="orderViewEditDeliveryAddressButton" v-if="!immutableStatuses.includes(orderLine.orderLineStatus) && hasPermission('setOrderLineDeliveryInfo')" class="c-shipping-info__edit" @click="edit">Edit</span></p>
            <table class="c-data-table">
                <tr>
                    <td>{{ form.firstName }} {{ form.lastName }}</td>
                </tr>
                <tr>
                    <td>{{ form.companyName }}</td>
                </tr>
                <tr>
                    <td>{{ form.address }} {{ form.addressAdditional }}, <template v-if="form.houseNumber">{{ form.houseNumber }}</template><template v-if="form.houseNumberAdditional">{{ form.houseNumberAdditional }}</template></td>
                </tr>
                <tr>
                    <td>{{ form.zipCode }} {{ form.city }}</td>
                </tr>
                <tr>
                    <td>{{ form.country }}</td>
                </tr>
            </table>
        </div>
        <div>
            <mercur-dialog data-e2e="orderViewSetAddressModal" :is-open.sync="isEditing">
                <h3 class="font-weight-normal mt-1">Set delivery address</h3>
                <p>
                    {{ form.firstName }} {{ form.lastName }} <br>
                    <span v-if="form.companyName">{{ form.companyName }} <br></span>
                    {{ form.address }} {{ form.addressAdditional }} {{ form.houseNumber }} {{ form.houseNumberAdditional}} <br>
                    {{ form.zipCode }} {{ form.city }} <br>
                    {{ form.country }}
                </p>
                <pretty-select
                    data-e2e="orderViewSetAddressModalAddressesSelector"
                    v-if="addresses.length"
                    label="label"
                    placeholder="Addresses"
                    :options="addresses"
                    v-model="selectedAddress"
                />
                <div v-if="customer.accountType === 'ANONYMOUS'">
                    <mercur-input v-model="draft.firstName" :class="getValidationClass($v, 'firstName', $v.draft)">
                        First name
                        <template #note>
                            <span class="form-error" v-if="!$v.draft.firstName.required">First name is required</span>
                        </template>
                    </mercur-input>
                    <mercur-input v-model="draft.lastName" :class="getValidationClass($v, 'lastName', $v.draft)">
                        Last name
                        <template #note>
                            <span class="form-error" v-if="!$v.draft.lastName.required">Last name is required</span>
                        </template>
                    </mercur-input>
                    <mercur-input v-model="draft.companyName">
                        Company
                    </mercur-input>
                    <mercur-input v-model="draft.address" :class="getValidationClass($v, 'address', $v.draft)">
                        Address
                        <template #note>
                            <span class="form-error" v-if="!$v.draft.address.required">Address is required</span>
                        </template>
                    </mercur-input>
                    <mercur-input v-model="draft.addressAdditional">
                        Address additional
                    </mercur-input>
                    <mercur-input v-model="draft.houseNumber">
                        House number
                    </mercur-input>
                    <mercur-input v-model="draft.houseNumberAdditional">
                        House number additional
                    </mercur-input>
                    <mercur-input v-model="draft.zipCode" :class="getValidationClass($v, 'zipCode', $v.draft)">
                        Zip code
                        <template #note>
                            <span class="form-error" v-if="!$v.draft.zipCode.required">Zip code is required</span>
                        </template>
                    </mercur-input>
                    <mercur-input v-model="draft.city" :class="getValidationClass($v, 'city', $v.draft)">
                        City
                        <template #note>
                            <span class="form-error" v-if="!$v.draft.city.required">City is required</span>
                        </template>
                    </mercur-input>
                </div>
                <template #footer>
                    <mercur-button class="btn" @click.native="cancel" :disabled="loading">Cancel</mercur-button>
                    <mercur-button data-e2e="orderViewSetAddressModalAddressesSaveButton" class="btn btn-primary" @click.native="updateAddress" :disabled="loading">Save</mercur-button>
                </template>
            </mercur-dialog>
        </div>
    <shipment-events-list/>
    </mercur-card>
</template>

<script>
import CONFIG from '@root/config'
import { mapState, mapActions } from 'vuex'
import ShipmentEventsList from '@/components/elements/order/ShipmentEventsList'
import PrettySelect from '@/components/utils/PrettySelect'
import FormMixin from '@/mixins/Form'
import { required } from 'vuelidate/lib/validators'
import HelperMethods from '@/components/utils/HelperMethods'

export default {
    name: 'ShippingInfo',
    mixins: [FormMixin, HelperMethods],
    components: { ShipmentEventsList, PrettySelect },
    data () {
        return {
            isEditing: false,
            form: {},
            draft: {},
            immutableStatuses: CONFIG.ORDERS.IMMUTABLE_STATUSES,
            selectedAddress: null,
            loading: false,
        }
    },
    watch: {
        orderLine: {
            handler (value) {
                this.form = value.deliveryInfo.address
            },
            immediate: true,
        },
    },
    computed: {
        ...mapState('order', ['order', 'orderLine']),
        ...mapState('customer', ['addresses', 'customer']),
        action () {
            return CONFIG.API.ROUTES.ORDERS.DELIVERY.replace('{accountId}', this.$route.params.accountId).replace('{orderId}', this.order.orderId).replace('{orderLineId}', this.orderLine.orderLineId)
        },
    },
    validations: {
        draft: {
            firstName: { required },
            lastName: { required },
            address: { required },
            zipCode: { required },
            city: { required },
            country: { required },
        },
    },
    methods: {
        ...mapActions('order', ['setOrder']),
        updateAddress () {
            if (this.customer.accountType === 'ANONYMOUS') {
                if (this.$v) {
                    this.$v.$touch()
                }

                if (this.$v && this.$v.$invalid) {
                    return
                }
                this.selectedAddress = this.draft
            }

            if (!this.verifyShippingAddress(this.selectedAddress, true)) {
                return
            }

            this.selectedAddress.countryCode = this.countries.find((e) => e.countryName === this.selectedAddress.country).countryShortCode

            const payload = {
                orderLine: {
                    ...this.orderLine,
                    vatProfile: this.applicationVatProfiles[this.selectedAddress.countryCode],
                },
                address: this.selectedAddress,
            }

            this.loading = true
            this.post(this.action, payload, 'Orderline delivery address was updated').then((data) => {
                this.$set(this, 'form', data.data.deliveryInfo.address)
                if (data.vatData) {
                    const event = data.vatData.data.find(e => e.event === 'OrderTotalsChanged')
                    if (event) {
                        const vats = event.response.data
                        const order = JSON.parse(JSON.stringify(this.order))
                        order.totals = vats.totals
                        order.orderLines.forEach((orderLine, index) => {
                            order.orderLines[index].orderLineTotals = vats.orderLinesTotals[orderLine.orderLineId]
                        })

                        this.setOrder({
                            data: order,
                            currentOrderline: this.$route.params.orderLineId,
                        })
                    }
                }
            }).finally(() => {
                this.isEditing = false
                this.loading = false
            })
        },
        edit () {
            this.isEditing = true
            this.draft = JSON.parse(JSON.stringify(this.form))
        },
        cancel () {
            this.isEditing = false
            this.selectedAddress = null
        },
    },
}
</script>

<style lang="scss" scoped>
    .c-shipping-info {
        &__edit {
            float: right;
            text-decoration: underline;
            cursor: pointer;
        }

        .c-data-table td:first-child {
            padding-right: 10px;
        }
    }

    .address-dialog {
        min-width: 600px;
        min-height: 500px;
    }
</style>
